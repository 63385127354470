@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.hidden-mobile {
  display: none !important;
}

.hidden-desktop {
  display: inline-flex !important;
}

@media (min-width: 550px) {
  .hidden-mobile {
    display: inline-block !important;
  }

  .hidden-desktop {
    display: none !important;
  }
}

[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}
